<template>
  <div class="full_h full_w d-flex align-center justify-center">
    <div class="pa-5 d-flex align-center justify-center flex-column">
      <div class="parent_img">
        <v-img src="../../assets/img/photo Login.png" class="img_login">
        </v-img>
      </div>
      <div class="mt-3">
        <h4 v-if="!success">
          Silakan klik tombol 'Verifikasi' untuk meverifikasi akun anda
        </h4>
        <h4 v-if="success">
          Silakan klik tombol 'Login' untuk masuk kehalaman biro
        </h4>
      </div>

      <v-btn
        v-if="!success"
        @click="verify"
        depressed
        block
        dark
        class="rounded mt-3 text-capitalize bg_color1"
      >
        <h4 v-if="!loading">Verifikasi</h4>
        <h4 v-if="loading">Loading...</h4>
      </v-btn>
      <v-btn
        v-if="success"
        to="/"
        depressed
        block
        dark
        class="rounded mt-3 text-capitalize bg_color1"
      >
        <h4>Login</h4>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "verification",
  data() {
    return {
      loading: false,
      success: false,
    };
  },
  methods: {
    // fungsi verifikasi
    verify() {
      this.loading = true;
      let data = {
        path: `auth/verifikasi/${this.$route.params.idverify}`,
      };
      this.$store
        .dispatch("fileData/getData", data)
        .then((data) => {
          this.loading = false;
          this.success = true;
          if (data.status == "success") {
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          } else {
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "error",
            });
          }
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
